import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import {NoHeadFootLayout} from '../components/layout';
import landingImg from '../images/undraw/5.svg' // relative path to image 
import * as ROUTES from '../constants/routes';
const LandingPage = () => (
  <Fragment>
      <div className="page-content pb-0">
          
          <div className="card" data-card-height="cover" style={{height: '100vh'}}>
              <div className="card-center text-center">
                  <div className="content mr-5 ml-5 mb-0">
                      <h1 className="mt-5 mb-0 font-40"> ViP IoT Plus </h1>
                      <p className="mt-1 color-highlight font-15">Internet of things for smart agriculture</p>
                      {/* <p className="boxed-text-xl">
                          Powered by Boostrap 4.4 with AJAX Transitions providing full
                          PWA, RTL and Dark Mode integrations!
                        </p> */}
                        <img className="preload-img img-fluid pl-2 pr-2 " style={{maxHeight: '50vh'}} src={landingImg} />
                      <Link to={ROUTES.SIGN_IN} data-back-button className="btn scale-box btn-m mt-5 btn-center-l rounded-l shadow-xl bg-highlight font-800 text-uppercase">Get Started</Link>
                      <br/>
                      <p className="boxed-text-xl">
                          Powered by VIP Agriculture Group
                      </p>
                      {/* <a href="#">Get Started</a> */}
                  </div>
              </div>
          </div>     

        </div>
  </Fragment>
);

export default () => (
  <NoHeadFootLayout>
    <LandingPage />
  </NoHeadFootLayout>
);
